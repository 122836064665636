import "@fortawesome/fontawesome-free/css/all.css";
import "./index.html";
import "./styles.css";
import * as d3 from "d3";
var canvas = d3.select("#canvas");
var pixelData = [];
var width = 32;
var height = 8;
var history = [];
var historyIndex = -1;
var MAX_HISTORY = 200;
var TOOLS = ["Pen", "Fill", "FillNeighbors", "Pick"];
var TOOL_CAPTIONS = {
    Fill: "Fill",
    FillNeighbors: "Fill Neighbors",
    Pen: "Pen",
    Pick: "Pick",
};
var TOOL_ICONS = {
    Fill: "fill-drip",
    FillNeighbors: "fill-drip",
    Pen: "pen",
    Pick: "thermometer",
};
var endpoint = "";
var DEFAULT_COLORS = ["#000000", "#ffffff", "#ff0000", "#00ff00", "#0000ff", "#ffff00", "#00ffff", "#ff00ff"];
var tool = "Pen";
var previousTool = "Pen";
(function initialize() {
    var hist = localStorage.getItem("history");
    var index = localStorage.getItem("historyIndex");
    if (hist && index) {
        history = JSON.parse(hist);
        historyIndex = JSON.parse(index);
    }
    if (history.length === 0) {
        updateDimensions(width, height);
    }
    else {
        goHistory(0);
    }
}());
function updateDimensions(newWidth, newHeight, storeInHistory) {
    if (storeInHistory === void 0) { storeInHistory = true; }
    width = newWidth;
    height = newHeight;
    for (var y = 0; y < height; y++) {
        pixelData[y] = pixelData[y] || [];
        for (var x = 0; x < width; x++) {
            pixelData[y][x] = pixelData[y][x] || "#000000";
        }
        pixelData[y] = pixelData[y].slice(0, width);
    }
    pixelData = pixelData.slice(0, height);
    draw();
    sendImage(storeInHistory);
}
var colorPicker = d3.select("#colorPicker");
function drawColorButton() {
    d3.select("#colorButton")
        .style("background-color", colorPicker.property("value"))
        .style("color", d3.hsl(colorPicker.property("value")).l < 0.5 ? "white" : "black");
}
drawColorButton();
colorPicker.on("change", drawColorButton);
function setColor(color) {
    colorPicker.property("value", color);
    drawColorButton();
}
function drawHistoryButtons() {
    if (historyIndex > 0) {
        d3.select("#undo").attr("disabled", null);
    }
    else {
        d3.select("#undo").attr("disabled", "disabled");
    }
    if (historyIndex < history.length - 1) {
        d3.select("#redo").attr("disabled", null);
    }
    else {
        d3.select("#redo").attr("disabled", "disabled");
    }
}
drawHistoryButtons();
function pushHistoryState() {
    history = history.slice(0, historyIndex + 1);
    if (history.length >= MAX_HISTORY) {
        history = history.slice(1);
        historyIndex -= 1;
    }
    var pixelDataClone = pixelData.map(function (row) { return row.slice(); });
    history.push({ pixelData: pixelDataClone, width: width, height: height });
    historyIndex += 1;
    localStorage.setItem("history", JSON.stringify(history));
    localStorage.setItem("historyIndex", JSON.stringify(historyIndex));
    drawHistoryButtons();
}
function goHistory(delta) {
    var newIndex = historyIndex + delta;
    if (newIndex >= 0 && newIndex < history.length) {
        historyIndex += delta;
        drawHistoryButtons();
        var state = history[historyIndex];
        pixelData = state.pixelData.map(function (row) { return row.slice(); });
        updateDimensions(state.width, state.height, false);
    }
}
d3.select("#undo").on("click", function () {
    d3.event.preventDefault();
    goHistory(-1);
});
d3.select("#redo").on("click", function () {
    d3.event.preventDefault();
    goHistory(1);
});
function css2int(c) {
    return parseInt(c.substr(1), 16);
}
var ws;
function sendMessage(message) {
    if (endpoint) {
        if (!ws) {
            ws = new WebSocket(endpoint);
            ws.onopen = function () {
                if (ws) {
                    ws.send(message);
                }
            };
            ws.onclose = function () {
                ws = undefined;
            };
            ws.onerror = function () {
                ws = undefined;
                endpoint = "";
                drawConnectButton();
            };
            ws.onmessage = function (m) {
                console.error(m.data);
            };
        }
        else {
            ws.send(message);
        }
    }
}
function sendImage(storeInHistory) {
    if (storeInHistory === void 0) { storeInHistory = true; }
    if (storeInHistory) {
        pushHistoryState();
    }
    var message = JSON.stringify(pixelData.map(function (row) { return row.map(css2int); }));
    sendMessage(message);
}
function sendPixel(x, y, c, storeInHistory) {
    if (storeInHistory === void 0) { storeInHistory = true; }
    if (storeInHistory) {
        pushHistoryState();
    }
    var message = JSON.stringify({ x: x, y: y, c: css2int(c) });
    sendMessage(message);
}
var lastPaintedPixel = { x: -1, y: -1 };
var numPaintedPixels = 0;
function colorPixel(x, y) {
    var c = colorPicker.property("value");
    pixelData[y][x] = c;
    if (lastPaintedPixel.x !== x || lastPaintedPixel.y !== y) {
        numPaintedPixels += 1;
        lastPaintedPixel = { x: x, y: y };
    }
}
function fillPixel(startX, startY) {
    var myColor = pixelData[startY][startX];
    for (var y = 0; y < height; y++) {
        for (var x = 0; x < width; x++) {
            if (pixelData[y][x] === myColor) {
                colorPixel(x, y);
            }
        }
    }
}
function fillNeighbors(x, y) {
    var myColor = pixelData[y][x];
    if (myColor === colorPicker.property("value")) {
        return;
    }
    colorPixel(x, y);
    if (x > 0 && pixelData[y][x - 1] === myColor) {
        fillNeighbors(x - 1, y);
    }
    if (x < width - 1 && pixelData[y][x + 1] === myColor) {
        fillNeighbors(x + 1, y);
    }
    if (y > 0 && pixelData[y - 1][x] === myColor) {
        fillNeighbors(x, y - 1);
    }
    if (y < height - 1 && pixelData[y + 1][x] === myColor) {
        fillNeighbors(x, y + 1);
    }
}
function pickColor(x, y) {
    setColor(pixelData[y][x]);
    switchTool(previousTool);
}
function actionPixel(x, y) {
    if (tool === "Pen") {
        colorPixel(x, y);
    }
    else if (tool === "Fill") {
        fillPixel(x, y);
    }
    else if (tool === "FillNeighbors") {
        fillNeighbors(x, y);
    }
    else if (tool === "Pick") {
        pickColor(x, y);
    }
    draw();
}
function doSend() {
    if (numPaintedPixels === 1) {
        var x = lastPaintedPixel.x, y = lastPaintedPixel.y;
        sendPixel(x, y, pixelData[y][x]);
    }
    else if (numPaintedPixels > 1) {
        sendImage();
    }
    numPaintedPixels = 0;
    lastPaintedPixel = { x: -1, y: -1 };
}
function colorHeatMap() {
    var colors = {};
    pixelData.forEach(function (row) { return row.forEach(function (c) {
        if (!colors[c]) {
            colors[c] = 0;
        }
        colors[c] += 1;
    }); });
    return colors;
}
function switchTool(t) {
    previousTool = tool;
    tool = t;
    toolActive = false;
    drawTools();
}
function drawTools() {
    var tools = d3.select("#tools").selectAll("button").data(TOOLS).call(updateTool);
    tools.enter().append("button")
        .html(function (d) { return "<i class=\"fas fa-2x fa-" + TOOL_ICONS[d] + "\"></i><br>" + TOOL_CAPTIONS[d]; })
        .on("click", switchTool).call(updateTool);
    tools.exit().remove();
    function updateTool(elem) {
        elem.attr("class", function (d) { return (tool === d) ? "active" : ""; });
    }
}
drawTools();
var toolActive = false;
function draw() {
    var rows = canvas.selectAll("div.row").data(pixelData).call(updateRows);
    rows.enter().append("div").attr("class", "row").call(updateRows);
    rows.exit().remove();
    function updateRows(elem) {
        elem.each(function (dd, y) {
            var row = d3.select(this);
            var pixel = row.selectAll("div.pixel").data(dd).call(updatePixel);
            pixel.enter()
                .append("div").attr("class", "pixel")
                .on("mousedown", function (d, x) {
                d3.event.preventDefault();
                if (d3.event.button === 0) {
                    toolActive = true;
                    actionPixel(x, y);
                }
            })
                .on("mousemove", function (d, x) {
                d3.event.preventDefault();
                if (d3.event.buttons & 1 && toolActive) {
                    actionPixel(x, y);
                }
            })
                .on("mouseup", function (d, x) {
                d3.event.preventDefault();
                toolActive = false;
                doSend();
            })
                .call(updatePixel);
            pixel.exit().remove();
        });
    }
    function updatePixel(elem) {
        elem.style("background-color", function (d) { return d; });
    }
    var heatMap = colorHeatMap();
    var commonColors = Object.keys(heatMap).sort(function (a, b) { return heatMap[b] - heatMap[a]; }).slice(0, 10);
    var colors = d3.select("#colors").selectAll("div").data(commonColors).call(updateColors);
    colors.enter().call(enterColors);
    colors.exit().remove();
    var defaultColors = d3.select("#defaultColors").selectAll("div").data(DEFAULT_COLORS).call(updateColors);
    var foo = defaultColors.enter().call(enterColors);
    defaultColors.exit().remove();
    function updateColors(elem) {
        elem.style("background-color", function (d) { return d; });
    }
    function enterColors(elem) {
        elem.append("div").attr("class", "color").call(updateColors)
            .on("click", function (d) {
            setColor(d);
        });
    }
}
function savePng() {
    var png = document.createElement("canvas");
    png.width = width;
    png.height = height;
    document.body.appendChild(png);
    var ctx = png.getContext("2d");
    var imageData = ctx.getImageData(0, 0, width, height);
    var data = imageData.data;
    for (var y = 0; y < height; y++) {
        for (var x = 0; x < width; x++) {
            var c = pixelData[y][x];
            var r = parseInt(c.substr(1, 2), 16);
            var g = parseInt(c.substr(3, 2), 16);
            var b = parseInt(c.substr(5, 2), 16);
            data[(y * width + x) * 4] = r;
            data[(y * width + x) * 4 + 1] = g;
            data[(y * width + x) * 4 + 2] = b;
            data[(y * width + x) * 4 + 3] = 255;
        }
    }
    ctx.putImageData(imageData, 0, 0);
    var url = png.toDataURL("image/png");
    var a = document.createElement("a");
    a.href = url;
    a.download = "lafabi.png";
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
        document.body.removeChild(a);
        document.body.removeChild(png);
        window.URL.revokeObjectURL(url);
    });
}
d3.select("#save").on("click", function () {
    d3.event.preventDefault();
    savePng();
});
function toHex(i) {
    return ("00" + i.toString(16)).substr(-2);
}
function loadImage() {
    var file = document.createElement("input");
    file.type = "file";
    file.style.visibility = "hidden";
    file.accept = "image/*";
    document.body.appendChild(file);
    file.click();
    file.addEventListener("change", function () {
        if (file.files) {
            var f = file.files[0];
            var url = URL.createObjectURL(f);
            var img_1 = document.createElement("img");
            img_1.src = url;
            img_1.addEventListener("load", function () {
                var png = document.createElement("canvas");
                var ctx = png.getContext("2d");
                ctx.drawImage(img_1, 0, 0);
                var imageData = ctx.getImageData(0, 0, width, height);
                var data = imageData.data;
                for (var y = 0; y < height; y++) {
                    for (var x = 0; x < width; x++) {
                        var r = toHex(data[(y * width + x) * 4]);
                        var g = toHex(data[(y * width + x) * 4 + 1]);
                        var b = toHex(data[(y * width + x) * 4 + 2]);
                        pixelData[y][x] = "#" + r + g + b;
                    }
                }
                draw();
                sendImage();
            });
        }
        document.body.removeChild(file);
    });
}
d3.select("#load").on("click", function () {
    d3.event.preventDefault();
    loadImage();
});
d3.select("#size").on("click", function () {
    d3.event.preventDefault();
    var newDim = prompt("Size of the image in pixels:", width + " x " + height);
    if (newDim) {
        var m = newDim.match(/^(\d+)\s*x\s*(\d+)/);
        if (m) {
            var w = +m[1];
            var h = +m[2];
            if (w <= 100 && h <= 100) {
                updateDimensions(+m[1], +m[2]);
            }
            else {
                alert("Dimensions exceed maximum of 100 pixel.");
            }
        }
        else {
            alert("Wrong dimension format. Use something like " + (width + " x " + height));
        }
    }
});
d3.select("#size8").on("click", function () {
    d3.event.preventDefault();
    updateDimensions(8, 8);
});
d3.select("#size32").on("click", function () {
    d3.event.preventDefault();
    updateDimensions(32, 8);
});
function fill(color) {
    for (var y = 0; y < height; y++) {
        for (var x = 0; x < width; x++) {
            pixelData[y][x] = color;
        }
    }
    draw();
    sendImage();
}
d3.select("#fillBlack").on("click", function () {
    d3.event.preventDefault();
    fill("#000000");
});
d3.select("#fillColor").on("click", function () {
    d3.event.preventDefault();
    fill(colorPicker.property("value"));
});
function drawConnectButton() {
    d3.select("#connect").classed("connected", function () { return !!endpoint; });
}
d3.select("#connect").on("click", function () {
    d3.event.preventDefault();
    if (endpoint) {
        endpoint = "";
    }
    else {
        var defaultEndpoint = localStorage.getItem("endpoint") || "ws://lafabi.local/set";
        var answer = prompt("Websocket endpoint of LaFabi running paint server:", defaultEndpoint);
        if (answer) {
            endpoint = answer;
            localStorage.setItem("endpoint", answer);
            sendImage(false);
        }
        else {
            endpoint = "";
        }
    }
    drawConnectButton();
});
d3.select("#colorButton").on("click", function () {
    document.getElementById("colorPicker").click();
});
